/* App.css */
.App {
  text-align: center;
  background-color: #f0f0f0; /* Change background color to light gray */
  padding: 10px; /* Add padding to provide space around the charts */
}

.title {
  margin-bottom: 20px;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Add margin to separate rows */
}



.chartWrapper {
  margin: 10px;
  padding: 10px;
  background-color: #ffffff; /* White background for chart wrappers */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
